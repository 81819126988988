
import { defineComponent, PropType } from 'vue';
import { Transaction} from '@/models';
import NumberFormatter from '@/components/NumberFormatter.vue';

export default defineComponent({
    props: {
        transaction: { type: Object as PropType<Transaction>, required: true }
    },
    setup({ transaction }) {
        return {
            transaction
        };
    },
    components: { NumberFormatter }
})
