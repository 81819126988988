<template>
    <div>
    </div>
</template>

<script>
export default {
    name: 'Promotions',
    components:{
       
    },
}
</script>

<style scoped>

</style>