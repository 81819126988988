
import { defineComponent, onMounted, ref, computed } from "vue";
import TransactionsTable from "./transactions-table.vue";


export default defineComponent({
    name: "transactions",
    setup() {
        
    },
    components: { TransactionsTable }
});
